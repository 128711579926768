import CreatePwd from "../Components/Pages/Auth/CreatePwd";
import ForgetPwd from "../Components/Pages/Auth/ForgetPwd";
import LoginOne from "../Components/Pages/Auth/LoginOne";
import LoginSweetalert from "../Components/Pages/Auth/LoginSweetalert";
import RegisterSimple from "../Components/Pages/Auth/RegisterSimple";
import ErrorPage4 from "../Components/Pages/ErrorPages/ErrorPage404";
import Logins from "../Auth/Signin";
import LoginForm from "../Components/Pages/Auth/LoginForm";

export const authRoutes = [
  {path: `/login`, Component: <Logins />},
  {
    path: `/pages/authentication/login-simple/:layout`,
    Component: <LoginForm />,
  },
  {path: `/pages/authentication/login-img/:layout`, Component: <LoginOne />},
  {
    path: `/pages/authentication/login-sweetalert/:layout`,
    Component: <LoginSweetalert />,
  },
  {
    path: `/register`,
    Component: <RegisterSimple />,
  },
  {
    path: `/reset-password`,
    Component: <ForgetPwd />,
  },
  {
    path: `/users/reset/password/:id/:type/:token`,
    Component: <CreatePwd />,
  },

  //Error Pages
  {path: `/pages/errors/error404/:layout`, Component: <ErrorPage4 />},
];

import React, {useEffect, useState} from "react";
import {Btn, H4, P} from "../../../AbstractElements";
import {Form, FormGroup, Input, Label, Media} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import Request from "../../../api/httpClient";
import {toast} from "react-toastify";
import Logo from "../../../assets/images/login/logo-login.png";
import Develoments from "../../../assets/images/login/develoments.png";
import {MD5} from "crypto-js";

const request = new Request();

const LoginForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [userEmail, setUserEmail] = useState(
    localStorage.getItem("userEmail") || ""
  );
  const [password, setPassword] = useState(
    localStorage.getItem("password") || ""
  );
  const [userId, setUserId] = useState(localStorage.getItem("user_id") || 0);
  const [name, setName] = useState(localStorage.getItem("Name"));
  const [role, setRole] = useState(localStorage.getItem("Role"));
  const [developments, setDevelopments] = useState(
    localStorage.getItem("developments" || [])
  );

  const encryptPassword = (pwd) => {
    const sanitized = pwd.replace(/[^a-zA-Z0-9]/g, "");
    const reversed = sanitized.split("").reverse().join("");
    return MD5(reversed).toString();
  };

  const loginAuth = async (e) => {
    e.preventDefault();

    if (!userEmail.trim()) {
      toast.error("El campo de correo electrónico es obligatorio", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    if (!/\S+@\S+\.\S+/.test(userEmail)) {
      toast.error("Por favor, introduce un correo electrónico válido", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    if (!password.trim()) {
      toast.error("El campo de contraseña es obligatorio", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    setLoading(true);

    const data = {email: userEmail};
    const response = await request.post("/usuarios/usuario/login", data);

    const encryptedPassword = encryptPassword(password);

    if (response) {
      if (response.password === encryptedPassword) {
        setUserId(response.pkuser);
        setName(response.username);
        setRole(response.roll);
        setDevelopments(response.developments);

        localStorage.setItem("login", JSON.stringify(true));
        localStorage.setItem("horaLogin", new Date().getTime());
        localStorage.setItem("userEmail", userEmail);

        if (rememberMe) {
          localStorage.setItem("password", password);
        } else {
          localStorage.removeItem("password");
        }

        navigate("/dashboard");
        toast.success("Bienvenido", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      } else {
        toast.error("Revise si sus credenciales son correctas", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    } else {
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    localStorage.setItem("user_id", userId);
    localStorage.setItem("Name", name);
    localStorage.setItem("Role", role);
    localStorage.setItem("developments", developments);
  }, [userId, name, role, developments]);

  return (
    <div className="login-card">
      <div className="login-main">
        <div className="logo" to={process.env.PUBLIC_URL}>
          <Media src={Logo} alt="Logo" style={{width: "25%"}} />
        </div>
        <Form className="theme-form login-form">
          <H4 attrH4={{className: "text-center text-light m-b-10"}}>
            Sistema de Registro Eleva Capital Group
          </H4>
          <FormGroup>
            <Input
              className="form-control"
              type="text"
              required
              placeholder="Email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup className="position-relative">
            <Input
              className="form-control"
              type="password"
              name="login[password]"
              required
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormGroup>
          {loading ? (
            <div className="loader-box mt-3" style={{height: "20px"}}>
              <div className="loader-15" />
            </div>
          ) : (
            <Btn
              attrBtn={{
                className: "d-block w-100 mt-3",
                color: "primary",
                type: "submit",
                onClick: loginAuth,
              }}
            >
              Iniciar sesión
            </Btn>
          )}
          <div
            className="m-t-10 text-center fst-italic"
            style={{color: "#fff", fontSize: "12px"}}
          >
            Si tienes problemas para iniciar sesión, envía un email a
            sistemas@elevacap.com con nombre completo y correo electrónico.
          </div>
          <P attrPara={{className: "m-t-10 mb-0 text-center text-light"}}>
            <Link className="ms-2" to="/register">
              ¿Eres nuevo? Regístrate
            </Link>
          </P>
          <div className="position-relative">
            <div className="checkbox">
              <Input
                id="remember-me"
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <Label style={{color: "blue"}} htmlFor="remember-me">
                Recordar Contraseña
              </Label>
            </div>
            <Link className="link" to="/reset-password">
              ¿Olvidaste tu contraseña?
            </Link>
          </div>
          <div className="m-t-10 text-center">
            <div style={{color: "#D3AB1D"}}>
              Al registrarte en nuestro sistema, asegura tu cliente y tu
              comisión.
            </div>
            <div style={{color: "#fff"}}>
              En cuanto recibas tu registro, un asesor de Eleva Capital Group se
              pondrá en contacto contigo.
            </div>
          </div>
          <div className="m-t-10 text-center">
            <Media
              src={Develoments}
              alt="Developments"
              style={{width: "90%"}}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;

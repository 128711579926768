import React from "react";
import Select from "react-select";
import {Col} from "reactstrap";
import ProspectRecoveryEmail from "../ProspectRecoveryEmail/ProspectRecoveryEmail";
import ProspectToggle from "../ProspectToggle/ProspectToggle";

const ProspectFilters = (props) => {
  const {
    developments,
    selectedDeveoment,
    setSelectedDevelopment,
    stage,
    setStage,
    setOtherUsersInfo,
    otherUsersInfo,
    isLoading,
    emailRecuperacion,
    setEmailRecuperacion,
    loadingProspectos,
  } = props;
  const userRole = localStorage.getItem("Role");

  const developmentsOptions = [
    {IDdesarrollo: 0, nomDesarrollo: "Todos", gdrive_disponibilidad: ""},
    ...developments,
  ];

  const options = developmentsOptions.map((des) => ({
    value: des.nomDesarrollo,
    label: des.nomDesarrollo,
  }));

  const handleChange = (selectedOption) => {
    const desarrolloSeleccionado = developmentsOptions.find(
      (des) => des.nomDesarrollo === selectedOption.value
    );
    setSelectedDevelopment(desarrolloSeleccionado);
  };

  return (
    <>
      <Col sm="3">
        <Select
          placeholder={"Seleccionar proyecto"}
          defaultValue={options[0]}
          value={selectedDeveoment}
          options={options}
          onChange={handleChange}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 3,
            }),
          }}
        />
      </Col>
      <Col sm="3">
        <Select
          options={stage}
          placeholder={"Seleccionar etapa"}
          onChange={(etapa) => setStage(etapa ? etapa.value : "")}
          isLoading={isLoading}
          isClearable
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 3,
            }),
          }}
        />
      </Col>
      <ProspectRecoveryEmail
        emailRecuperacion={emailRecuperacion}
        setEmailRecuperacion={setEmailRecuperacion}
        loadingProspectos={loadingProspectos}
      />
      {userRole === "A" && (
        <ProspectToggle
          setOtherUsersInfo={setOtherUsersInfo}
          otherUsersInfo={otherUsersInfo}
        />
      )}
    </>
  );
};

export default ProspectFilters;

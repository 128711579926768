import React from "react";
import {Suspense, useEffect, useState} from "react";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import Callback from "../Auth/Callback";
import Loader from "../Layout/Loader";
import {authRoutes} from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import {ToastContainer} from "react-toastify";

const verificarSesion = () => {
  var horaLogin = localStorage.getItem("horaLogin");

  if (new Date().getTime() - horaLogin > 12 * 60 * 60 * 1000) {
    localStorage.setItem("login", false);
    return false;
  } else {
    return true;
  }
};

const Routers = () => {
  const login = useState(verificarSesion())[0];
  const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem("token");

  useEffect(() => {
    let abortController = new AbortController();

    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <BrowserRouter basename={"/"}>
      <Suspense fallback={<Loader />}>
        <ToastContainer />
        <Routes>
          <Route path={"/"} element={<PrivateRoute />}>
            {login || authenticated || jwt_token ? (
              <>
                <Route
                  exact
                  path={``}
                  element={<Navigate to={`/dashboard`} />}
                />
                <Route
                  exact
                  path={`/`}
                  element={<Navigate to={`/dashboard`} />}
                />
              </>
            ) : (
              ""
            )}
            <Route path={`/*`} element={<LayoutRoutes />} />
          </Route>
          <Route path={`/callback`} render={() => <Callback />} />
          <Route exact path={`/login`} element={<Signin />} />
          {authRoutes.map(({path, Component}, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;

import {useState, useEffect} from "react";
import moment from "moment";
import Request from "../../api/httpClient";
import {ReportsColumns} from "../../Data/Table/ReportsColumns";
import {ratings} from "../../Data/options";

const request = new Request();

const useReports = () => {
  const columns = ReportsColumns();
  const userRole = localStorage.getItem("Role");
  const developments = JSON.parse(localStorage.getItem("developments") || "[]");
  const [isLoading, setIsLoading] = useState(false);
  const [prospects, setProspects] = useState([]);
  const [from, setFrom] = useState(moment().clone().startOf("month").toDate());
  const [to, setTo] = useState(moment().clone().endOf("month").toDate());
  const [selectedDevelopment, setSelectedDevelopment] = useState(null);
  const [selectedRating, setSelectedRating] = useState(0);
  const [allDevelopments, setAllDevelopments] = useState([]);

  const filteredData = prospects.filter(
    ({nomDesarrollo, calificacion}) =>
      (!selectedDevelopment || nomDesarrollo === selectedDevelopment.value) &&
      (!selectedRating || calificacion === selectedRating)
  );

  const fetchProspects = async () => {
    setIsLoading(true);
    const data = {
      from: moment(from).format("YYYY-MM-DD HH:mm:ss"),
      to: moment(to).format("YYYY-MM-DD HH:mm:ss"),
      developments: developments ? developments.map((d) => d.id) : 0,
    };

    const response = await request.post(
      "/reportes/get/historialMarketing",
      data
    );
    if (response && !response.error) {
      if (response && !response.empty) {
        setProspects(response);
      } else {
        setProspects([]);
      }
    } else {
      console.error(response.message);
    }
    setIsLoading(false);
  };

  const fetchDevelopments = async () => {
    setAllDevelopments([]);
    const response = await request.get("/desarrollos/get/all");
    if (response && !response.error) {
      if (response) {
        const options = response.map((development) => ({
          id: development.IDdesarrollo,
          value: development.nomDesarrollo,
          label: development.nomDesarrollo,
        }));

        setAllDevelopments(options);
      } else {
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
  };

  const handleReport = async () => {
    const data = {
      from: moment(from).format("YYYY-MM-DD 00:00:00"),
      to: moment(to).format("YYYY-MM-DD 23:59:59"),
      development: selectedDevelopment?.id || 0,
      rating: selectedRating,
    };

    const response = await request.post("/reportes/prospects", data);
    return response;
  };

  useEffect(() => {
    fetchProspects();
    fetchDevelopments();
  }, []);

  return {
    columns,
    isLoading,
    from,
    setFrom,
    to,
    setTo,
    fetchProspects,
    handleReport,
    developments,
    setSelectedDevelopment,
    filteredData,
    ratings,
    setSelectedRating,
    allDevelopments,
    userRole,
  };
};

export default useReports;
